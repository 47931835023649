import classNames from "classnames";
import StyledSelect from "components/form/select/StyledSelect";
import Heading from "components/typography/heading/Heading";
import Paths from "constants/Routes";
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { selectOptions } from "utils/utils";
import Icon from "../icon/Icon";
import "./Company.scss";

const fetchCompany = async () => {
  const res = await fetch(`/${document.documentElement.lang}/api${Paths.API.COMPANY_GET}`);
  return res.json();
};

const updateCompany = async (data: CompanyData) => {
  const csrfToken = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')?.content;
  let formData = new FormData();
  formData.append("authenticity_token", csrfToken ?? "");
  formData.append("adults", data.adults.toString());
  formData.append("minors", data.minors.toString());

  const res = await fetch(`/${document.documentElement.lang}/api${Paths.API.COMPANY_UPDATE}`, { method: "POST", body: formData });
  return res;
};

const defaultCompanyValues = { adults: 1, minors: 0, children: 0, babies: 0, teenagers: 0, group_booking: false, total_guests: 1, group_ok: true, max_guests: 19 };

const Company: FunctionComponent = () => {
  const [companyData, setCompanyData] = useState<CompanyData>(defaultCompanyValues);
  const [labels, setLabels] = useState<QueryLabelData | undefined>();
  const { adults, minors, group_booking, max_guests } = companyData;

  const { data, refetch } = useQuery<QueryData>("company", fetchCompany, { refetchOnWindowFocus: false });
  const { mutate } = useMutation(updateCompany);

  useEffect(() => {
    if (data) {
      setCompanyAmount();
    }
  }, [data]);

  const getNewApiData = useCallback(() => {
    refetch();
  }, [refetch]);

  const setCompanyAmount = useCallback(() => {
    if (!data) return;
    let newValues = { ...companyData, ...data.group };
    setLabels(data.labels);
    setCompanyData(newValues);
    window.postMessage({ companyValue: newValues, refetchCompanySubData: true });
  }, [data]);

  const resetCompany = useCallback(() => {
    mutate(defaultCompanyValues, {
      onSuccess: () => {
        setCompanyData(() => {
          window.postMessage({ companyValue: defaultCompanyValues });
          return defaultCompanyValues;
        });
      },
    });
  }, []);

  const listener = useCallback((e) => {
    if (e.data.resetCompany) {
      resetCompany();
    }
    if (e.data.refetchCompanyData) {
      getNewApiData();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, [listener]);

  const handleCompany = useCallback(
    (e: ChangeEvent<HTMLSelectElement>, field: string) => {
      let formData = companyData;
      switch (field) {
        case "adults":
          formData = { ...formData, adults: Number(e.target.value) };
          break;
        case "minors":
          formData = { ...formData, minors: Number(e.target.value) };
          break;
      }
      setCompanyData(formData);
      mutate(formData, {
        onSuccess: (res) => {
          getNewApiData();
        },
      });
    },
    [setCompanyData, mutate, companyData]
  );

  return (
    <div className="company-selector">
      <div className="company-selector__wrapper">
        <div className={classNames("company-selector__item", { "is-active": adults > 0 })}>
          <div className="company-selector__item-icon">
            <Icon name={adults > 0 ? "womanOpen" : "womanClosed"} size="xlarge" />
          </div>

          <div className="company-selector__item-text">
            <span className="caps">{labels?.persons_heading}</span>
            <Heading level={4} className="react-heading--grey">
              {labels?.adults}
            </Heading>
          </div>

          <div className="company-selector__item-controls">
            <StyledSelect options={selectOptions(1, max_guests)} value={companyData.adults} handleChange={(e) => handleCompany(e, "adults")} />
          </div>
        </div>

        <div className={classNames("company-selector__item", { "is-active": minors > 0 })}>
          <div className="company-selector__item-icon">
            <Icon name={minors > 0 ? "childOpen" : "childClosed"} size="xlarge" />
          </div>

          <div className="company-selector__item-text">
            <span className="caps">{labels?.persons_heading}</span>
            <Heading level={4} className="react-heading--grey">
              {labels?.minors}
            </Heading>
          </div>

          <div className="company-selector__item-controls">
            <StyledSelect options={selectOptions(0, max_guests)} value={companyData.minors} handleChange={(e) => handleCompany(e, "minors")} />
          </div>
        </div>
        {group_booking && (
          <div className="company-selector__group-booking">
            <a href={labels?.group_booking_link}>{labels?.group_booking}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Company;
